import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { MutableRefObject, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

import { emailValidation, EMAIL_CONTACT_POINT_SYSTEM } from "utils";
import { ITelecom } from "models";
import { useCheckEmailExists } from "hooks";
import { useTelecomErrors } from "commons";
import { TelecomErrorType } from "types";

const TelecomContainer: React.FC<IProps> = ({
  phones,
  emails,
  setTelecoms,
  checkEmailExists,
  patientOrganization,
}) => {
  const { telecomErrors, setTelecomErrors, setIsCheckingEmail } =
    useTelecomErrors();

  const firstTime = useRef(true);

  const handleTelecomOnChange = (index: number) => (telecom: ITelecom) => {
    if (telecom.system === EMAIL_CONTACT_POINT_SYSTEM) {
      setTelecoms(
        [...emails.slice(0, index), telecom, ...emails.slice(index + 1)],
        phones
      );
    } else {
      setTelecoms(emails, [
        ...phones.slice(0, index),
        telecom,
        ...phones.slice(index + 1),
      ]);
    }
  };

  const handleSetEmailError = (index: number) => (error: TelecomErrorType) => {
    setTelecomErrors({
      ...telecomErrors,
      emails: [
        ...telecomErrors.emails.slice(0, index),
        error,
        ...telecomErrors.emails.slice(index + 1),
      ],
    });
  };

  const handleSetPhoneError = (index: number) => (error: TelecomErrorType) => {
    setTelecomErrors({
      ...telecomErrors,
      phones: [
        ...telecomErrors.phones.slice(0, index),
        error,
        ...telecomErrors.phones.slice(index + 1),
      ],
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      {emails.map((email, index) => (
        <EmailFields
          key={index}
          telecom={email}
          onChange={handleTelecomOnChange(index)}
          error={telecomErrors.emails[index]}
          setIsCheckingEmail={setIsCheckingEmail}
          setError={handleSetEmailError(index)}
          checkEmailExists={checkEmailExists}
          patientOrganization={patientOrganization}
        />
      ))}
      {phones.map((phone, index) => (
        <PhoneFields
          key={index}
          telecom={phone}
          onChange={handleTelecomOnChange(index)}
          error={telecomErrors.phones[index]}
          firstTime={firstTime}
          setError={handleSetPhoneError(index)}
        />
      ))}
    </div>
  );
};

const EmailFields: React.FC<ITelecomFieldProps & IEmailProps> = ({
  telecom,
  onChange,
  error,
  setError,
  setIsCheckingEmail,
  checkEmailExists: checkEmail,
  patientOrganization,
}) => {
  const handleOnSuccessCheckEmail = (exists: boolean) => {
    if (exists) {
      setError({
        hasError: true,
        msg: "Email is already in use",
        isTouched: true,
      });
    } else {
      setError({ hasError: false, msg: "", isTouched: true });
    }
  };
  const { checkEmailExists, isCheckingEmail, exists } = useCheckEmailExists(
    handleOnSuccessCheckEmail
  );

  useEffect(() => {
    setIsCheckingEmail(isCheckingEmail);
  }, [isCheckingEmail, setIsCheckingEmail]);

  useEffect(() => {
    if (!error.hasError && telecom.value && checkEmail) {
      checkEmailExists({
        emailToCheck: telecom.value,
        patientOrganization,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnchange = (telecom: ITelecom) => {
    const isEmailValid = emailValidation(telecom.value);

    if (!telecom.value) {
      setError({
        hasError: true,
        msg: "Email address is required",
        isTouched: true,
      });
    } else {
      if (isEmailValid) {
        setError({ hasError: false, msg: "", isTouched: true });

        if (checkEmail) {
          checkEmailExists({
            emailToCheck: telecom.value,
            patientOrganization,
          });
        }
      } else {
        setError({ hasError: true, msg: "Wrong email", isTouched: true });
      }
    }

    onChange(telecom);
  };

  return (
    <div className="flex items-center h-full relative">
      <div className="field w-full">
        <label>Email*</label>
        <InputText
          type="email"
          className={classNames("w-full p-inputtext-sm", {
            "p-invalid": error.hasError && error.isTouched,
          })}
          value={telecom.value}
          onChange={(event) =>
            handleOnchange({ ...telecom, value: event.target.value })
          }
        />
      </div>
      {!isCheckingEmail && error.isTouched && error.hasError && (
        <small className="p-error block absolute -bottom-3">
          {error.msg}
          {exists && patientOrganization && (
            <a
              className="text-xs text-blue-500 underline ml-1 cursor-pointer"
              target="_blank"
              href={window.REACT_APP_PATIENT_PORTAL_URL}
              rel="noreferrer"
            >
              Already registered? Click here to Login
            </a>
          )}
        </small>
      )}
      {isCheckingEmail && (
        <small className="block absolute -bottom-3">
          <FontAwesomeIcon icon={faSpinner} spin />
          <span>Checking email availability...</span>
        </small>
      )}
    </div>
  );
};

const PhoneFields: React.FC<
  ITelecomFieldProps & {
    firstTime: MutableRefObject<boolean>;
  }
> = ({ telecom, onChange, error, setError, firstTime }) => {
  const handleOnChange = (telecom: ITelecom) => {
    if (!telecom.value) {
      if (!firstTime.current) {
        setError({
          hasError: true,
          msg: "Phone number is required",
          isTouched: true,
        });
      } else {
        firstTime.current = false;
      }
    } else {
      setError({ hasError: false, msg: "", isTouched: true });
    }

    onChange(telecom);
  };

  return (
    <div className="flex items-center h-full relative">
      <div className="field w-full">
        <label>Phone*</label>
        <InputMask
          type="tel"
          mask="+1 (999) 999-9999"
          unmask={true}
          className={classNames("w-full p-inputtext-sm", {
            "p-invalid": error.hasError && error.isTouched,
          })}
          value={telecom.value}
          onChange={(event) => {
            handleOnChange({ ...telecom, value: event.target.value });
          }}
        />
      </div>
      {error.hasError && error.isTouched && (
        <small className="p-error block absolute -bottom-3">{error.msg}</small>
      )}
    </div>
  );
};

type ITelecomFieldProps = {
  telecom: ITelecom;
  onChange(phone: ITelecom): void;
  error: TelecomErrorType;
  setError(error: TelecomErrorType): void;
};

type IEmailProps = {
  checkEmailExists?: boolean;
  setIsCheckingEmail: React.Dispatch<React.SetStateAction<boolean>>;
  patientOrganization?: { id: string; name: string };
};

type IProps = {
  checkEmailExists?: boolean;
  phones: ITelecom[];
  emails: ITelecom[];
  setTelecoms(emails: ITelecom[], phones: ITelecom[]): void;
  patientOrganization?: { id: string; name: string };
};

export { TelecomContainer };
