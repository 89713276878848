import { useCallback, useState } from "react";
import { Button } from "primereact/button";

import {
  getInitialPatientState,
  PatientForm,
  getInitialPatientFormErrors,
  useCreatePatient,
  toPatient,
} from "patient";
import { ReponseRedirectType } from "hooks";
import { getInitialTelecomErrors, ONBOARD_VIEWS } from "utils";
import { TelecomErrorsProvider } from "commons";
import { usePractitioners } from "practitioner";
import { ApplySettings } from "settings";

import { Onboarded } from "./Onboarded";

const OnboardPatient: React.FC<IProps> = ({ orgId }) => {
  const { practitioners } = usePractitioners(orgId);

  const [patientFormValues, setPatientFormValues] = useState(
    getInitialPatientState(
      orgId,
      practitioners.length === 1 ? practitioners?.[0]?.value : ""
    )
  );
  const [patientFormError, setPatientFormError] = useState(
    getInitialPatientFormErrors
  );
  const [telecomErrors, setTelecomErrors] = useState(
    getInitialTelecomErrors(patientFormValues.emails, patientFormValues.phones)
  );
  const [redirectUrl, setRedirectUrl] = useState("");

  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  const handleOnSuccess = (response: ReponseRedirectType) => {
    setRedirectUrl(response.redirectUrl);
  };
  const { createPatient, isLoading } = useCreatePatient(handleOnSuccess);

  const isDisabled = isLoading || isCheckingEmail;

  const isPatientValid = useCallback(() => {
    let errors = { ...getInitialPatientFormErrors };
    let isValid = true;

    if (!patientFormValues.firstName) {
      errors.firstName = true;
      isValid = false;
    }

    if (!patientFormValues.lastName) {
      errors.lastName = true;
      isValid = false;
    }

    if (
      patientFormValues.address.line1 ||
      patientFormValues.address.line2 ||
      patientFormValues.address.city ||
      patientFormValues.address.state ||
      patientFormValues.address.postalCode
    ) {
      if (!patientFormValues.address.line1) {
        errors.line1 = true;
        isValid = false;
      }

      if (!patientFormValues.address.city) {
        errors.city = true;
        isValid = false;
      }

      if (!patientFormValues.address.state) {
        errors.state = true;
        isValid = false;
      }

      if (!patientFormValues.address.postalCode) {
        errors.postalCode = true;
        isValid = false;
      }
    }

    if (!patientFormValues.birthdate) {
      errors.birthdate = true;
      isValid = false;
    }

    if (!patientFormValues.gender) {
      errors.gender = true;
      isValid = false;
    }

    if (!patientFormValues.practitioner) {
      errors.practitioner = true;
      isValid = false;
    }

    if (telecomErrors.emails.some(({ hasError }) => hasError)) {
      isValid = false;
    }

    if (telecomErrors.phones.some(({ hasError }) => hasError)) {
      isValid = false;
    }

    setPatientFormError((prev) => ({ ...prev, ...errors }));

    return isValid;
  }, [patientFormValues, setPatientFormError, telecomErrors]);

  const onSubmit = () => {
    setTelecomErrors((prev) => ({
      emails: prev.emails.map((email) => ({ ...email, isTouched: true })),
      phones: prev.phones.map((phone) => ({ ...phone, isTouched: true })),
    }));

    const isValid = isPatientValid();

    if (isValid) {
      const patient = toPatient(patientFormValues);

      createPatient(patient);
    }
  };

  if (redirectUrl) {
    const name = `${patientFormValues.firstName} ${patientFormValues.lastName}`;

    return (
      <>
        <ApplySettings orgId={orgId} isPatientPage />
        <Onboarded
          name={name}
          redirectUrl={redirectUrl}
          onboardView={ONBOARD_VIEWS.PATIENT}
          email={patientFormValues.emails?.[0]?.value}
        />
      </>
    );
  }

  return (
    <TelecomErrorsProvider
      isCheckingEmail={isCheckingEmail}
      setIsCheckingEmail={setIsCheckingEmail}
      telecomErrors={telecomErrors}
      setTelecomErrors={setTelecomErrors}
    >
      <div className="relative">
        <ApplySettings orgId={orgId} isPatientPage />
        <div className="flex flex-1 justify-end absolute lg:right-0 lg:top-8 top-24">
          <a
            type="button"
            className="p-button-text hover:underline transition-all ease-in-out duration-200 p-3 rounded-lg hover:bg-secondary-hover/20"
            href={window.REACT_APP_PATIENT_PORTAL_URL}
          >
            <strong className="text-secondary">
              Already registered? Click here to Login
            </strong>
          </a>
        </div>
        <form className="w-full space-y-5">
          <PatientForm
            formValues={patientFormValues}
            setFormValues={setPatientFormValues}
            formError={patientFormError}
          />
        </form>

        <hr className="bg-gray-300 my-10" />

        <div className="flex justify-end items-center w-full">
          <Button
            className="w-full md:w-min"
            type="button"
            label="Save"
            onClick={onSubmit}
            loading={isDisabled}
          />
        </div>
      </div>
    </TelecomErrorsProvider>
  );
};

type IProps = {
  orgId: string;
};

export { OnboardPatient };
